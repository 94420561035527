<template>
  <div class="account-activation">
    <password-chooser
      button-label="Activate Account"
      @choose="handlePwdChoice"
    >
      Please choose a strong password for your account.
    </password-chooser>
  </div>
</template>

<script>
import { verifyEmail } from '@/adonis-api/auth';
import { userDefaultPath } from '@/router/router-helpers';
import PasswordChooser from '../../global/PasswordChooser.vue';

export default {
  components: { PasswordChooser },
  data() {
    const confirmPass = (checkField, rule, value, callback) => {
      if (this.resetForm[checkField] !== value) {
        if (checkField === 'confirm' && this.resetForm[checkField] === '') {
          return callback();
        }
        return callback(new Error('The passwords do not match.'));
      }

      callback();
    };

    return {
      activationForm: {
        password: '',
        confirm: '',
      },
      formRules: Object.freeze({
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur',
          },
          {
            validator: confirmPass.bind(this, 'confirm'),
            trigger: 'blur',
          },
        ],
        confirm: [
          {
            required: true,
            message: 'You must confirm the password you entered.',
            trigger: 'blur',
          },
          {
            validator: confirmPass.bind(this, 'password'),
            trigger: 'blur',
          },
        ],
      }),
    };
  },

  methods: {
    async handlePwdChoice(password) {
      this.loader = this.$loading({
        lock: true,
        text: 'Verifying email address...',
      });

      try {
        const authInfo = await verifyEmail({
          password,
          token: this.$route.params.pathMatch,
        });

        await this.$store.dispatch('auth/forceLogin', authInfo);
        this.$router.push(userDefaultPath(this.$router));
      } catch (e) {
        if (e.message === 'The token is invalid or expired') {
          this.$notify.error({
            message: 'This link has expired. A new welcome email has been sent, please use the link in the new email to activate your account.',
          });
        } else {
          this.$notify.error({
            message:
              'There was a problem verifying your account. This issue has been reported.',
          });
          this.$reportError(e);
        }
      } finally {
        this.loader.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-activation {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}
</style>
