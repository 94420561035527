<template>
  <div class="container">
    <div v-if="!passResetRequest && showForm === 'login'">
      <h3>Sign In</h3>
      <div class="box">
        <el-form
          :model="signinForm"
          @submit.native.prevent
        >
          <el-form-item prop="email">
            <el-input
              v-model="signinForm.email"
              name="email"
              type="email"
              placeholder="Email Address"
            >
              <template slot="prepend">
                <i class="fa fa-envelope" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="signinForm.password"
              name="password"
              type="password"
              placeholder="Password"
            >
              <template slot="prepend">
                <i class="fa fa-lock" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              native-type="submit"
              type="primary"
              :loading="busy"
              @click="doLogin"
            >
              Sign In
            </el-button>
            <el-button
              type="text"
              @click="switchToForm('resetRequest')"
            >
              Forgot password?
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-else-if="passResetRequest">
      <h3>Password Reset</h3>
      <div class="box">
        <el-form :model="resetForm">
          <el-form-item prop="password">
            <el-input
              v-model="resetForm.password"
              type="password"
              placeholder="New Password"
            >
              <template slot="prepend">
                <i class="fa fa-lock" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="confirm">
            <el-input
              v-model="resetForm.confirm"
              type="password"
              placeholder="Confirm New Password"
            >
              <template slot="prepend">
                <i class="fa fa-lock" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="doPasswordReset"
            >
              Reset Password
            </el-button>
            <el-button
              type="text"
              @click="switchToForm('login')"
            >
              &laquo; Back to Sign In
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-else-if="showForm === 'resetRequest'">
      <h3>Password Reset</h3>
      <div class="box">
        <el-form :model="resetRequestForm">
          <el-form-item prop="email">
            <el-input
              v-model="resetRequestForm.email"
              name="email"
              type="email"
              placeholder="Email Address"
            >
              <template slot="prepend">
                <i class="fa fa-envelope" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="requestPasswordReset"
            >
              Send Password Reset Email
            </el-button>
            <el-button
              type="text"
              @click="switchToForm('login')"
            >
              &laquo; Back to Sign In
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { forgotPassword } from '@/adonis-api/auth';
import { mapGetters } from 'vuex';
import validator from 'validator';

export default {
  data() {
    return {
      busy: false,
      showForm: 'login',
      signinForm: {
        email: '',
        password: '',
      },
      resetForm: {
        password: '',
        confirm: '',
      },
      resetRequestForm: {
        email: '',
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['passResetRequest']),
  },

  methods: {
    async doLogin() {
      if (
        !this.signinForm.email ||
        !this.signinForm.password ||
        !validator.isEmail(this.signinForm.email)
      ) {
        this.$notify.error('Please enter a valid email address and password.');
        return false;
      }

      this.busy = true;

      try {
        await this.$store.dispatch('auth/login', {
          email: this.signinForm.email,
          password: this.signinForm.password,
        });

        this.$router.push(_get(this.$route, 'query.redir') || '/');
      } catch (e) {
        this.busy = false;

        switch (e.message) {
          case 'InvalidLogin':
            this.$notify.error({
              title: 'Invalid Login',
              message:
                'Either the username or password you supplied is incorrect. Please try again.',
            });
            break;
          case 'LoginDisabled':
            this.$notify.error({
              title: 'Account Disabled',
              message:
                'This account has been disabled. Please contact the administrator.',
            });
            break;
          case 'UnknownException':
            this.$notify.error({
              title: 'Unknown Error',
              message:
                "An unknown error has occurred. We've been notified of the issue.",
            });
            this.$reportError(e);
            break;
          default:
            this.$reportError(e);
            break;
        }
      }
    },

    doPasswordReset() {
      if (!this.resetForm.password || !this.resetForm.confirm) {
        this.$message.error(
          'You must supply a password and enter it again for confirmation.',
        );
        return false;
      }
      if (this.resetForm.password !== this.resetForm.confirm) {
        this.$message.error('Passwords do not match.');
        return false;
      }
      if (
        !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(this.resetForm.password)
      ) {
        this.$message.error(
          'Password should containd at least one number, one lowercase, one uppercase letter and minimum six characters.',
        );
      }

      this.busy = true;
      // FIXME:
      // Accounts.resetPassword(
      //   global.passReset.token,
      //   this.resetForm.password,
      //   err => {
      //     if (err) {
      //       console.log(err);
      //       this.$message.warning({
      //         message: 'Unable to process your request.',
      //       });
      //       this.busy = false;
      //       return false;
      //     }
      //     this.$message.info({
      //       message: 'Your password has been changed.',
      //     });

      //     this.$store.dispatch('auth/passResetRequest', false);
      //     this.busy = false;
      //     global.passReset.callback();
      //     this.$router.go('/');
      //   }
      // );
    },

    async requestPasswordReset() {
      if (
        !this.resetRequestForm.email ||
        !validator.isEmail(this.resetRequestForm.email)
      ) {
        this.$notify.error('Please enter a valid email address.');
        return false;
      }

      this.busy = true;

      try {
        await forgotPassword({
          email: this.resetRequestForm.email,
        });

        this.$message.info({
          message:
            'An email has been sent to you with password reset instructions.',
        });
      } catch (e) {
        this.$message.warning({
          message: 'Unable to process your request.',
        });
      } finally {
        this.busy = false;
      }
    },

    switchToForm(formName) {
      this.showForm = formName;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 80px auto;
  width: 380px !important;
  text-align: center;
}

.box {
  width: 340px;
  padding: 20px 20px 5px;
  background: #efefef;
  border-radius: 8px;
  border: 1px solid #ccc;

  .el-button {
    width: 100%;
  }

  .el-button--text {
    margin-left: 0;
    font-size: 0.85rem;
  }
}
</style>
