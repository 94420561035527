<template>
  <div>
    <login-reset-form />
  </div>
</template>

<script>
import LoginResetForm from '@/components/global/LoginResetForm.vue';

export default {
  components: { LoginResetForm },
};
</script>

<style>

</style>
