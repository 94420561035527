<template>
  <div v-loading.fullscreen.lock="busy">
    <div v-if="busy" />
    <div
      v-else-if="success"
      class="message"
    >
      Thank you for confirming your email address! You may
      <a href="/">proceed to the application</a> if you wish.
    </div>
    <div
      v-else
      class="message failed"
    >
      There was a problem verifying your email address. This issue has been
      reported.
    </div>
  </div>
</template>

<script>
import { verifyEmail } from '@/adonis-api/auth';

export default {
  data() {
    return {
      busy: true,
      success: true, // we'll set to false if things fail
    };
  },

  async created() {
    try {
      await verifyEmail({
        token: this.$route.params.pathMatch,
      });
    } catch (e) {
      this.$reportError(e);
      this.success = false;
    } finally {
      this.busy = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.message {
  background: lighten($--color-success, 50%);
  border: 1px solid lighten($--color-success, 30%);
  border-radius: 2px;
  margin: 2em;
  padding: 2em;

  &.failed {
    background: lighten($--color-danger, 50%);
    border: 1px solid lighten($--color-danger, 30%);
  }
}
</style>
